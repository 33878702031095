/*  From https://www.npmjs.com/package/jsondiffpatch
  * Convert css to scss and modify colors
  *
*/
@import '@xpo-ltl/ngx-ltl-core/styles/_variables';

$xpo-green--json-diff: #BBFFBB;
$xpo-red--json-diff: #FFBBBB;

.jsondiffpatch-delta,
.jsondiffpatch-delta pre {
  display: inline-block;
  font-family: Source Code Pro, monospace;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  padding: 0 0 0 12px;
}

.jsondiffpatch-delta pre {
  padding: 0;
}

ul.jsondiffpatch-delta,
.jsondiffpatch-delta ul {
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 20px;
}

.jsondiffpatch-added .jsondiffpatch-property-name,
.jsondiffpatch-added .jsondiffpatch-value pre,
.jsondiffpatch-modified .jsondiffpatch-right-value pre,
.jsondiffpatch-textdiff-added {
  background: $xpo-green--json-diff;
  color: $xpo-black;
}

.jsondiffpatch-deleted .jsondiffpatch-property-name,
.jsondiffpatch-deleted pre,
.jsondiffpatch-modified .jsondiffpatch-left-value pre,
.jsondiffpatch-textdiff-deleted {
  background: $xpo-red--json-diff;
  color: $xpo-black;
  text-decoration: line-through;
}

.jsondiffpatch-unchanged,
.jsondiffpatch-movedestination {
  color: $xpo-white;
}

.jsondiffpatch-unchanged,
.jsondiffpatch-movedestination > .jsondiffpatch-value {
  overflow-y: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.jsondiffpatch-unchanged-showing .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-showing .jsondiffpatch-movedestination > .jsondiffpatch-value {
  max-height: 100px;
}

.jsondiffpatch-unchanged-hidden .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-hidden .jsondiffpatch-movedestination > .jsondiffpatch-value {
  max-height: 0;
}

.jsondiffpatch-unchanged-hiding .jsondiffpatch-movedestination > .jsondiffpatch-value,
.jsondiffpatch-unchanged-hidden .jsondiffpatch-movedestination > .jsondiffpatch-value {
  display: block;
}

.jsondiffpatch-unchanged-visible .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-visible .jsondiffpatch-movedestination > .jsondiffpatch-value {
  max-height: 100px;
}

.jsondiffpatch-unchanged-hiding .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-hiding .jsondiffpatch-movedestination > .jsondiffpatch-value {
  max-height: 0;
}

.jsondiffpatch-unchanged-showing .jsondiffpatch-arrow,
.jsondiffpatch-unchanged-hiding .jsondiffpatch-arrow {
  display: none;
}

.jsondiffpatch-value {
  display: inline-block;
}

.jsondiffpatch-property-name {
  display: inline-block;
  padding-right: 5px;
  vertical-align: top;
}

.jsondiffpatch-property-name::after {
  content: ': ';
}

.jsondiffpatch-child-node-type-array > .jsondiffpatch-property-name::after {
  content: ': [';
}

.jsondiffpatch-child-node-type-array::after {
  content: '],';
}

div.jsondiffpatch-child-node-type-array::before {
  content: '[';
}

div.jsondiffpatch-child-node-type-array::after {
  content: ']';
}

.jsondiffpatch-child-node-type-object > .jsondiffpatch-property-name::after {
  content: ': {';
}

.jsondiffpatch-child-node-type-object::after {
  content: '},';
}

div.jsondiffpatch-child-node-type-object::before {
  content: '{';
}

div.jsondiffpatch-child-node-type-object::after {
  content: '}';
}

.jsondiffpatch-value pre::after {
  content: ',';
}

li:last-child > .jsondiffpatch-value pre::after,
.jsondiffpatch-modified > .jsondiffpatch-left-value pre::after {
  content: '';
}

.jsondiffpatch-modified .jsondiffpatch-value {
  display: inline-block;
}

.jsondiffpatch-modified .jsondiffpatch-right-value {
  margin-left: 5px;
}

.jsondiffpatch-moved .jsondiffpatch-value {
  display: none;
}

.jsondiffpatch-moved .jsondiffpatch-moved-destination {
  background: $xpo-yellow--200;
  color: $xpo-grey--650;
  display: inline-block;
}

.jsondiffpatch-moved .jsondiffpatch-moved-destination::before {
  content: ' => ';
}

ul.jsondiffpatch-textdiff {
  padding: 0;
}

.jsondiffpatch-textdiff-location {
  color: $xpo-grey--300;
  display: inline-block;
  min-width: 60px;
}

.jsondiffpatch-textdiff-line {
  display: inline-block;
}

.jsondiffpatch-textdiff-line-number::after {
  content: ',';
}

.jsondiffpatch-error {
  background: $xpo-red--json-diff;
  color: $xpo-black;
  font-weight: bold;
}
